import React from 'react';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { Link } from 'gatsby';

import { StaticImage } from 'gatsby-plugin-image';

import '../../generic-page.scss';
import { Row, Col } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

const HSRGuidesSimPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="hsr">
      <ul className="breadcrumb">
        <li>
          <Link to="/star-rail/">Honkai: Star Rail</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/star-rail/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Simulated Universe </li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/starrail/categories/category_sim.png"
            alt="Simulated Universe "
          />
        </div>
        <div className="page-details">
          <h1>Simulated Universe </h1>
          <h2>
            A guide for the Simulated Universe mode in Honkai: Star Rail that
            shows the best teams you can use there.
          </h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      <div className="page-content">
        <SectionHeader title="Overview" />
        <StaticImage
          src="../../../images/starrail/generic/guide_sim.jpg"
          alt="Guide"
        />
        <p>
          Simulated Universe is a roguelike game mode where the player will
          build a team of four Characters to challenge enemies in an enclosed
          world with the goal of clearing it by defeating the boss at the end of
          the enclosed world.
        </p>
        <p>
          This game mode will unlock shortly after you finish a side quest given
          to you by Herta after you finish the tutorial mission and can be
          entered through the Master Control Zone in Herta’s Space Station.
        </p>
        <p>
          Simulated Universe is divided into different nodes call Worlds. Each
          World is a set of semi-randomized stages that ranges from combat
          stages to rest areas to random text event stages that culminates in a
          boss stage at the end of each World.
        </p>
        <p>
          You start with only World 1 unlocked and can unlock the rest of the
          Worlds as you progress through the game and defeat the boss at the end
          of each World. Starting from World 3, each World will also have a set
          number of Difficulty Levels with each Difficulty offering more
          challenging enemies and better rewards. Note that you only need to
          clear the first Difficulty Level of a World to unlock the next World.
        </p>
        <SectionHeader title="Accessing Simulated Universe" />
        <StaticImage
          src="../../../images/starrail/generic/guide_sim1.jpg"
          alt="Guide"
        />
        <p>
          To enter the Simulated Universe, you will need to head to Herta’s
          Office in the Master Control Zone in Herta’s Space Station which is
          located at the bottom left of the area. Upon interacting with the
          Simulated Universe portal, you will be able to select the Simulated
          Universe World that you would like to challenge. From here, you will
          need to select four Characters from your roster to take with you into
          the Simulated Universe. These four Characters will be the only ones
          that you can use while inside the Simulated Universe, though you will
          be able to add more Characters to your team when you reach one of the
          Simulated Universe’s rest areas.
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
        <SectionHeader title="Simulated Universe Stages" />
        <p>
          Once inside the Simulated Universe, you will need to progress through
          a set number of stages culminating in a boss fight to complete the
          run. You can pause or leave a run early by opening the Menu screen and
          selecting the appropriate option. A run will also end if your whole
          team is wiped out.
        </p>
        <p>The different types of Simulated Universe stages are:</p>
        <p>
          <strong>Combat Stages</strong>
        </p>
        <ul>
          <li>
            These stages will have you fight either some mob enemies, an elite
            enemy, or a boss enemy. They are denoted with a red portal.
          </li>
          <li>
            You can identify what type of enemy you will be fighting by looking
            at the stage name:
          </li>
          <ul>
            <li>Domain - Combat is mob battles.</li>
            <li>Domain - Elite/Challenge is an elite battle.</li>
            <li>Domain - Boss is a boss battle.</li>
            <li>Domain - is a special combat challenge stage.</li>
          </ul>
          <li>
            At the end of each victorious battle, you will be presented with
            three random Blessings and you can select one of them to keep.
            Blessings are buffs for your team that persist for the duration of
            the current Simulated Universe run.
          </li>
          <li>
            Defeating the elite enemy on certain stages will also grant you a
            choice of three random Curios in addition to the three random
            Blessings. Curios are either buffs for your team that persist for
            the duration of the current Simulated Universe run or a one time use
            effect.
          </li>
          <li>
            You must defeat all of the enemies in a combat stage in order to
            progress to the next stage. Defeating the boss at the end of
            Simulated Universe World will complete the current run.
          </li>
        </ul>
        <p>
          <strong>
            Random Event Stages (Domain - Occurrence/Domain - Transaction)
          </strong>
        </p>
        <ul>
          <li>
            These stages are non-combat stages where you will receive a random
            event that generally allows you to obtain new Blessings or Curios at
            a cost. The cost can range from expending Cosmic Fragments to
            fighting enemies. They are denoted with a blue portal.
          </li>
          <li>
            Cosmic Fragments is a Simulated Universe currency that can be
            obtained by winning battles and destroying the breakable objects in
            the Simulated Universe.
          </li>
        </ul>
        <p>
          <strong>Rest Areas (Domain - Respite)</strong>
        </p>
        <ul>
          <li>
            These are non-combat stages that have breakable objects that let you
            restore a portion of your team’s HP and Technique Charges. They are
            denoted with a green portal.
          </li>
          <li>
            The rest area also lets you revive fallen Characters by spending
            Cosmic Fragments once you have unlocked the ability to do so from
            the Simulated Universe Ability Tree.
          </li>
          <li>
            There is also a terminal that you can interact with which lets you
            add one additional Character from your roster to your Simulated
            Universe team.
          </li>
          <li>
            You can talk to Herta in this area to do one of the following:
          </li>
          <ul>
            <li>Purchase a random Blessing for 80 Cosmic Fragments.</li>
            <li>Purchase a random Curio for 120 Cosmic Fragments.</li>
            <li>Randomly upgrade two Blessings for 180 Cosmic Fragments.</li>
            <li>
              Note that for the Domain - Respite that is right before the boss
              battle, talking to Herta will instead open up a menu where you can
              spend Cosmic Fragments to select and enhance the Blessings that
              you have collected.
            </li>
          </ul>
        </ul>
        <p>
          Your party’s HP and Technique Charges do not replenish at the end of a
          battle. You will need to either heal your party members in combat or
          destroy the green or purple containers to regenerate them. Similar to
          the overworld, the green containers will regenerate your party’s HP
          while the purple containers will restore 2 Technique Charges.
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
        <SectionHeader title="Blessings and Curios" />
        <p>
          The random buffs (and debuffs) that you can obtain during a Simulated
          Universe run comes in two forms: Blessings and Curios. Whenever you
          receive a Blessing or Curio, you will be able to choose from one of
          three randomly generated Blessings or Curios that you do not already
          have.
        </p>
        <p>
          Blessings are permanent buffs to your part that can be obtained by
          either winning battles, selecting certain options when inside either a
          Domain - Occurrence stage or a Domain - Transaction stage, or by
          purchasing them with Cosmic Fragments from Herta while you are inside
          a Domain - Respite stage.
        </p>
        <p>
          Blessings are divided into different groups or Paths with each Path
          corresponding to a different playstyle. Currently, there are 8 Paths
          available. They are:
        </p>
        <ul>
          <li>
            Preservation - focuses on providing shields and buffing shielded
            Characters.
          </li>
          <li>
            Remembrance - focuses on freezing the enemy and making frozen
            enemies take more damage.
          </li>
          <li>Nihility - focuses on inflicting DoT and buffing DoT damage.</li>
          <li>
            Abundance - focuses on healing and providing buffs to teammates that
            have been healed.
          </li>
          <li>
            Hunt - focuses on speed and manipulating the turn order while
            providing damage buffs.{' '}
          </li>
          <li>
            Destruction - focuses on providing buffs that get stronger the lower
            a Character’s HP is.
          </li>
          <li>Elation - focuses on enhancing follow up attacks.</li>
          <li>
            Propagation - focuses on enhancing basic attacks (you need to unlock
            this path via Swarm Disaster mode as it's not available before
            that).
          </li>
        </ul>
        <p>
          At the start of the game, you will only have access to the Blessings
          of Preservation, Remembrance, and Elation. However, more Paths will be
          unlocked as you progress further into the Simulated Universe.
        </p>
        <p>
          Blessings can also be enhanced via random events and at a Domain -
          Respite stage. An enhanced Blessing provides a stronger version of the
          buff that it is providing.
        </p>
        <p>
          Curios on the other hand have more varied effects and are unlocked
          after you gain access to Simulated Universe World 3. They can provide
          you with either permanent combat and non-combat buffs, a temporary
          boost or effect that expires after certain conditions are met, or even
          debuffs for your party. They can generally be obtained from Domain -
          Occurrence and Domain - Transaction stages or by purchasing them with
          Cosmic Fragments from Herta at a Domain - Respite stage. You also have
          the option to start a run with a Curio as well after unlocking the
          appropriate node in the Simulated Universe Ability Tree. There are
          also random events and a Curio that give you a chance to obtain Curios
          as well.{' '}
        </p>
        <SectionHeader title="Simulated Universe Ability Tree" />
        <StaticImage
          src="../../../images/starrail/generic/guide_sim2.jpg"
          alt="Guide"
        />
        <p>
          Ability Points can be used to unlock permanent upgrades for your
          Simulated Universe runs. These can range from providing stat bonuses
          to your team when inside the Simulated Universe to unlocking new
          abilities for you to use during your Simulated Universe runs.
        </p>
        <SectionHeader title="Simulated Universe Path Resonance" />
        <StaticImage
          src="../../../images/starrail/generic/guide_sim_path.jpg"
          alt="Guide"
        />
        <p>
          After unlocking the Path Resonance node in the Simulated Universe
          Ability Tree, you will be prompted to select a Path when you start a
          new Simulated Universe run. There are currently 7 different Paths that
          you can select from and they correspond to the 7 Paths of the
          Blessings that you can obtain in this game mode.
        </p>
        <p>
          Selecting a Path will provide you with a small party-wide buff as well
          as increasing the chance that Blessings of that Path will appear
          during your Simulated Universe run. Additionally, the Path that you
          select will also dictate which Path Resonance ability that you can
          obtain during your run. After you have obtained 3 Blessings of the
          Path that you selected, you will unlock the corresponding Path
          Resonance ability which is a powerful active ability that you can
          trigger during battle at any time as long as it has been fully
          charged.
        </p>
        <p>
          As you progress further down the Simulated Universe Ability Tree, you
          will also unlock the ability to enhance your Path Resonance ability.
          Each Path Resonance ability has 3 different enhancements that you can
          choose from. You can choose the 1st enhancement after obtaining 6
          Blessings of the corresponding Path, the 2nd enhancement after
          obtaining 10 Blessings of the corresponding Path, and the 3rd
          enhancement after obtaining 14 Blessings of the corresponding Path.
        </p>
        <p>List of available paths:</p>
        <Row xs={1} xxl={3} className="path-row">
          <Col>
            <div className="box path-box">
              <StaticImage
                src="../../../images/starrail/generic/path_pres.jpg"
                alt="Guide"
              />
              <h5>Path of Preservation</h5>
              <p>
                This path is one of the best ones for F2P players as it allows
                them to turtle up and deal damage based on the Shields. Sure it
                will take you ages to kill the boss, but you will do it.
                Eventually.
              </p>
              <h6>Perks</h6>
              <ul>
                <li>
                  Increase the max DMG absorption of the shield received by
                  characters by 10%,
                </li>
                <li>
                  Increases the chance for Blessing of Preservation to appear.
                </li>
              </ul>
              <h6>Resonance Skill</h6>
              <p>
                Spend 100 points of Energy to use this ability and resonate with
                the Path of Preservation, dealing Physical DMG to all enemies
                based on the total Shield between all Allies.
              </p>
            </div>
          </Col>
          <Col>
            <div className="box path-box">
              <StaticImage
                src="../../../images/starrail/generic/path_rem.jpg"
                alt="Guide"
              />
              <h5>Path of Remembrance</h5>
              <p>
                This path focuses on controlling the battlefield and Freezing
                enemies. While the Blessings can do it by themselves, you can
                also use M7 to apply even more Freezes - basically turning the
                enemies into statues that wait to be slaughtered. If you gather
                the right Blessings, this path allows you to cheese all Worlds
                available in the SU currently.
              </p>
              <h6>Perks</h6>
              <ul>
                <li>Reduces target enemy's Effect RES by 10%</li>
                <li>
                  Increases the chance for Blessing of Remembrance to appear.
                </li>
              </ul>
              <h6>Resonance Skill</h6>
              <p>
                Spend 100 points of Energy to use this ability and resonate with
                the Path of Remembrance, dealing Ice DMG to all enemies with a
                120% base chance to Freeze all enemies for 1 turn(s).
              </p>
            </div>
          </Col>
          <Col>
            <div className="box path-box">
              <StaticImage
                src="../../../images/starrail/generic/path_ela.jpg"
                alt="Guide"
              />
              <h5>Path of Elation</h5>
              <p>
                This path focuses on follow-up attacks so it isn't as universal
                as the other two, as there's only a few characters that actually
                use them (sure, there's a Blessing that turns any Ultimate into
                a follow-up attack, but it's sadly not enough). Still, if you
                have Clara, this Path is perfect for her as Counters are by
                default treated as follow-up attacks.
              </p>
              <h6>Perks</h6>
              <ul>
                <li>
                  Increases DMG dealt in characters' follow-up attacks by 24%,
                </li>
                <li>Increases the chance for Blessing of Elation to appear.</li>
              </ul>
              <h6>Resonance Skill</h6>
              <p>
                Spend 100 points of Energy to use this ability and resonate with
                the Path of Elation, dealing follow-up attacks of a random Type
                to all enemies 3-5 time(s).
              </p>
            </div>
          </Col>
        </Row>
        <p>
          Here are the other paths, listed in the order you unlock them
          (starting from World 2):
        </p>
        <Row xs={1} xxl={2} className="path-row">
          <Col>
            <div className="box path-box">
              <StaticImage
                src="../../../images/starrail/generic/path_hunt.jpg"
                alt="Guide"
              />
              <h5>Path of Hunt</h5>
              <p>
                If you have the right setup, this Path will allow you to kill
                the Boss the fastest compared to all other Paths. Seele and
                Bronya users love this path as it allows Seele to attack the
                boss multiple times per turn, while its sitting there and
                staring with venom with his eyes. But even if you don't have
                Seele, Dan actually works really good with this Path too.
              </p>
              <h6>Perks</h6>
              <ul>
                <li>Increases the characters' SPD by 6%,</li>
                <li>
                  Increases the chance for Blessing of The Hunt to appear.
                </li>
              </ul>
              <h6>Resonance Skill</h6>
              <p>
                Spend 100 points of Energy to use this ability and resonate with
                the Path of The Hunt, dealing Wind DMG to all enemies based on
                the current ATK of the character currently with the highest ATK.
              </p>
            </div>
          </Col>
          <Col>
            <div className="box path-box">
              <StaticImage
                src="../../../images/starrail/generic/path_destro.jpg"
                alt="Guide"
              />
              <h5>Path of Destruction</h5>
              <p>
                Path of Destruction is a high-risk, high-reward Path that's hard
                to use, but pretty rewarding if you get the right blessings.
                Some characters can be basically made unkillable with it and
                they can safely stay on low HP while killing all enemies.
              </p>
              <p>
                Also, some of its Blessing are among the strongest in the game.
                For example{' '}
                <strong className="hsr-rar rar-5">
                  Regression Inequality of Annihilation
                </strong>{' '}
                that splits the damage received by 1 character to all of them,
                making the team much more tankier and allowing you to prevent
                one-shots.
              </p>
              <h6>Perks</h6>
              <ul>
                <li>Reduces DMG Taken by the characters by 6%,</li>
                <li>
                  Increases the chance for Blessing of Destruction to appear.
                </li>
              </ul>
              <h6>Resonance Skill</h6>
              <p>
                Spend 100 points of Energy to use this ability and resonate with
                the Path of Destruction, dealing Fire DMG to all enemies based
                on the on the current HP difference between Max HP and current
                HP for all allies combined.
              </p>
            </div>
          </Col>
          <Col>
            <div className="box path-box">
              <StaticImage
                src="../../../images/starrail/generic/path_nihi.jpg"
                alt="Guide"
              />
              <h5>Path of Nihility</h5>
              <p>
                Path of Nihility is perfect for Serval, Sampo and other
                characters that apply DoT (damage over time) debuffs. The path
                will amplify their damage and automatically trigger the DoTs on
                the enemy, which helps with killing them faster.
              </p>
              <h6>Perks</h6>
              <ul>
                <li>Increases the characters' DoT by 15%,</li>
                <li>
                  Increases the chance for Blessing of Nihility to appear.
                </li>
              </ul>
              <h6>Resonance Skill</h6>
              <p>
                Spend 100 points of Energy to use this ability and resonate with
                the Path of Nihility, with a 80% base chance to apply Burn,
                Shock, Bleed and 2 stacks of Wind Shear on all enemies for 2
                turn(s).
              </p>
            </div>
          </Col>
          <Col>
            {' '}
            <div className="box path-box">
              <StaticImage
                src="../../../images/starrail/generic/path_abu.jpg"
                alt="Guide"
              />
              <h5>Path of Abundance</h5>
              <p>
                Path of Abundance is an utility path that provides a lot of
                Healing, but also makes your Healers much stronger. On top of
                that it can prevent allies from dying and also resist (and
                cleanse) debuffs - which makes it perfect against the Kafka boss
                you fight in World 5.
              </p>
              <h6>Perks</h6>
              <ul>
                <li>Increases the characters' HP recovery by 10%,</li>
                <li>
                  Increases the chance for Blessing of Abundance to appear.
                </li>
              </ul>
              <h6>Resonance Skill</h6>
              <p>
                Spend 100 points of Energy to use this ability and resonate with
                the Path of Abundance, restore HP for all allies by 50% of their
                respective Max HP, and increases their respective Max HP by 15%
                for 2 turn(s).
              </p>
            </div>
          </Col>
        </Row>
        <SectionHeader title="Rewards" />
        <StaticImage
          src="../../../images/starrail/generic/guide_sim_rewards.jpg"
          alt="Guide"
        />
        <p>
          Completing this game mode will reward you with Simulated Universe
          Points and Ability Points. The amount of points awarded depends on
          what World you are challenging and how many stages you manage to clear
          before your team is wiped out or you defeat the boss at the end of the
          World. The more difficult the stages that you are challenging the more
          Simulated Universe Points and Ability Points you will be awarded. Note
          that you will be given Simulated Universe Points and Ability Points,
          albeit at a lower amount, even if you fail to complete a run.
        </p>
        <p>
          Upon reaching certain Simulated Universe Point milestones, you will be
          able to claim rewards such as Stellar Jades and Herta Bonds (currency
          used in Herta’s Store). Simulated Universe Points are reset back to 0
          weekly, thus allowing you to claim the rewards on the point track
          every week.
        </p>
        <p>
          Beginning from World 3, you will also be able to spend Trailblaze
          Power to obtain Planetary Ornaments during a Simulated Universe run.
          Each World offers two different sets of Planetary Ornament with the
          higher Difficulty offering better rarity Planetary Ornament drops.
          After defeating the enemy in either a Domain - Elite stage or a Domain
          - Boss stage, you will be able to interact with a terminal and spend
          40 Trailblaze Power or an Immersifier to obtain Planetary Ornament
          item drops. Immersifiers can be obtained from the weekly Simulated
          Universe Points reward or by spending Trailblaze Power. Each
          Immersifer costs 40 Trailblaze Power to create and a maximum of 8
          Immersifiers can be stored at a time. The terminal in a Domain - Elite
          stage can only be interacted with once while the one in the Domain -
          Boss stage can be interacted with twice. Note that interacting with
          these terminals to obtain Planetary Ornaments is optional. You can
          simply proceed to the next stage or exit the run if you do not want to
          spend any Trailblaze Power or Immersifiers.
        </p>
        <Row className="video-row">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="gGKVu1KcCVE" />
          </Col>
        </Row>
        <SectionHeader title="Builds" />
        <p>
          Do you want to learn more about the Builds you can run in Simulated
          Universe to make it easier? Check out our other guide:
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Simulated Universe Builds"
            link="/star-rail/guides/simulated-universe-builds"
            image={
              <StaticImage
                src="../../../images/starrail/categories/category_sim2.png"
                alt="Simulated Universe"
              />
            }
          />
        </Row>
        <SectionHeader title="Video guide" />
        <p>For more information about SU, you can check the video below:</p>
        <Row className="video-row">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="7ISeImEJQBo" />
          </Col>
        </Row>
        <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      </div>
    </DashboardLayout>
  );
};

export default HSRGuidesSimPage;

export const Head: React.FC = () => (
  <Seo
    title="Simulated Universe | Honkai: Star Rail | Prydwen Institute"
    description="A guide for the Simulated Universe mode in Honkai: Star Rail."
  />
);
